import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'

import {Close} from '../components/icons'

class TeamTemplate extends Component {
  render() {
    let team = this.props.data.wordpressWpTeam
    let back = () => {
      window.history.back()
    }
    let profile_pic = '';
    if(team.acf.profile_picture.localFile.childImageSharp)
    {
        profile_pic = team.acf.profile_picture.localFile.childImageSharp.original.src;
    }
    return (
      <>
        <SEO title={he.decode(team.yoast_meta.yoast_wpseo_title)} bodyClass="team" description={team.yoast_meta.yoast_wpseo_metadesc} />
        <section className="team-member">
          <div className="team-member__inner container">
            <div className="col col--1of4">
              { team.title &&
                <h1>{team.title}</h1>
              }
              { team.acf.position &&
                <h4>{team.acf.position}</h4>
              }
              <button className="team-member__close team-member__close--top" onClick={back}>
                <Close />
              </button>
              <figure>
                <img src={profile_pic} alt={team.title} />
              </figure>
            </div>
            <div className="col col--1of3">
              { team.content &&
                <div className="team-member__content" dangerouslySetInnerHTML={{ __html: team.content }} />
              }
            </div>
            <div className="col col--1of4 col--last">
              <h4>Get in touch</h4>
              <p>
                <a href={'mailto:'+team.acf.email}>{team.acf.email}</a><br />
                <a href={'tel:'+team.acf.phone}>{team.acf.phone}</a><br />
                <a href={team.acf.linkedin}>LinkedIn</a>
              </p>
              <button className="team-member__close team-member__close--bottom" onClick={back}>
                <Close />
              </button>
            </div>
          </div>
        </section>
      </>
    )
  }
}


export default TeamTemplate

export const teamQuery = graphql`
  query currentTeamQuery($id: String!) {
    wordpressWpTeam(id: { eq: $id }) {
      title
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        profile_picture {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        position
        email
        phone
        linkedin
      }
    }
  }
`
